
import { defineComponent } from 'vue';
import { YololProject } from '@/interfaces/yolol/yololProject';
import { PublicUser } from '@/interfaces/social/publicUser';

export default defineComponent({
    name: 'YololProjectListItem',
    props: {
        yololProject: {
            type: Object,
            required: true,
        },
        creator: {
            type: Object,
            default: null,
        },
        likes: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        typedYololProject(): YololProject {
            return this.yololProject as YololProject;
        },
        typedCreator(): PublicUser | null {
            return this.creator as PublicUser | null;
        },
        previewImageStyle(): Record<string, string> {
            return {
                backgroundImage: this.yololProject && this.yololProject.previewImageUri ? `url('${this.yololProject.previewImageUri}')` : '',
            };
        },
    },
});
